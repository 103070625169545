import "../scss/app.scss";
import "bootstrap";
import Vue from "vue";
import VueDragscroll from "vue-dragscroll";
const carousels = require("./carousels");
Vue.use(VueDragscroll);
const app = new Vue({
  el: "#app",
  data: {
    isToggleMenu: false,
  },
  mounted() {
    carousels.carouselSideScrolling();
  },
});
